import { memo } from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-center h-screen p-5 bg-[#f7f7ff]">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-gray-800 mb-4 font-[PublicSans]">
          404
        </h1>
        <p className="text-xl text-gray-600 mb-8 font-[PublicSans]">
          Oops! Page not found
        </p>
        <img
          src={require("../../../assets/image/language.png")}
          alt="Error 404"
          className="max-w-full h-auto"
        />
        <p className="text-gray-600 mt-8 font-[PublicSans]">
          The page you are looking for might be elsewhere.
        </p>
        <button
          type="button"
          onClick={() => {
            navigate("/");
          }}
          className="text-blue-500 hover:underline mt-4 block font-[PublicSans] bg-gray-200 px-4 py-2 rounded-md w-full"
        >
          Go back to home
        </button>
      </div>
    </div>
  );
};

export default memo(NotFoundPage);
