const FooterComponent = () => {
  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <footer className="text-white p-4 sticky bottom-0 w-full backdrop-filter bg-slate-900">
      <div className="mx-auto sm:px-10 w-full flex justify-between flex-col md:flex-row transition-all duration-300">
        <div>
          <a href="https://yavar.ai/" className="font-[PublicSans]">Powered by Yavar Techworks</a>
        </div>
        <p className="font-[PublicSans]">
          &copy; {currentYear} {"Yavar AI Translator"}
        </p>
      </div>
    </footer>
  );
};

export default FooterComponent;
