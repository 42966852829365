import FileUploadError from "../../components/FileUploadError/FileUploadError";
import FileUploadSuccess from "../../components/FileUploadSuccess/FileUploadSuccess";
import LanguageDropdowns from "../../elements/LanguageDropdowns/LanguageDropdowns";
import { uploadFile } from "../../../services/uploadService";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { memo, useEffect, useState } from "react";
//Iocns
import { FaFilePdf } from "react-icons/fa";
import { FaFileImage } from "react-icons/fa";
import { IoDocumentText } from "react-icons/io5";

const QuickUpload = () => {
  const [isError, setIsError] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploadError, setIsUploadError] = useState<boolean>(false);
  const [downloadPdfUrl, setDownloadPdfUrl] = useState<string | null>(null);
  const [isUploadSuccess, setIsUploadSuccess] = useState<boolean>(false);
  const [selectedFileName, setSelectedFileName] = useState<null | string>(null);
  const [validationMessage, setValidationMessage] = useState("");
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);

  const languages = [
    { code: "en", name: "English" },
    { code: "ar", name: "Arabic" },
    { code: "ja", name: "Japanese" },
  ];

  const [currentLanguage, setCurrentLanguage] = useState("ja");
  const [convertToLanguage, setConvertToLanguage] = useState("en");

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        handleFileChange(acceptedFiles);
      },
      onError(err) {
        console.log("file upload error", err);
      },
      multiple: false,
      maxSize: 20e6,
      accept: {
        "application/pdf": [],
      },
    });

  const navigate = useNavigate();

  const downloadPDF = () => {
    if (downloadPdfUrl) {
      const pdfUrl = downloadPdfUrl;
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.target = "_blank";
      // link.download = "document.pdf"; // specify the filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleFileChange = (e: any) => {
    if (e[0]) {
      setSelectedFileName(e[0].name);
      setSelectedFile(e[0]);
      setIsError(false);
    }
  };

  const handleUpload = async () => {
    if (
      selectedFile &&
      convertToLanguage &&
      currentLanguage &&
      !validationMessage
    ) {
      setIsError(false);
      setIsUploadInProgress(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      uploadFile(formData, currentLanguage, convertToLanguage)
        .then((res: any) => {
          setIsUploadSuccess(true);
          const url = res?.file_url;
          setDownloadPdfUrl(url);
        })
        .catch((error: Error) => {
          console.error("file upload error:", error);
          setIsUploadError(true);
        })
        .finally(() => {
          setIsUploadInProgress(false);
        });
    } else {
      if (!selectedFile) {
        setIsError(true);
      }
    }
  };

  const validateLanguages = () => {
    if (!currentLanguage || !convertToLanguage) {
      setValidationMessage("Please select both current and target languages.");
    } else if (currentLanguage === convertToLanguage) {
      setValidationMessage(
        "Please select different languages for current and target."
      );
    } else {
      setValidationMessage("");
    }
  };

  const handleLanguageChange = (languageType: string, languageCode: any) => {
    console.log("languageType", languageType);
    if (languageType === "current") {
      setCurrentLanguage(languageCode);
    } else {
      setConvertToLanguage(languageCode);
    }
  };

  useEffect(() => {
    validateLanguages();
  }, [convertToLanguage, currentLanguage]);

  const hideSuccessPopup = () => {
    setIsUploadSuccess(false);
    setSelectedFile(null);
    setSelectedFileName(null);
  };

  const retryFileUpload = () => {
    setIsUploadError(false);
    setSelectedFile(null);
    setSelectedFileName(null);
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full h-full flex flex-col justify-center items-center px-3 shadow-inner">
        {!isUploadSuccess && !isUploadError && (
          <div className="max-w-[640px] w-full text-center flex flex-col gap-y-5">
            <div
              className={`bg-[#fff] p-4 sm:p-5 rounded-[10px] shadow-lg transition-all duration-300`}
            >
              <h1 className="text-[#130f40] pb-3 text-xl font-medium font-[PublicSans]">
                Upload a file
              </h1>
              <div className="rounded-md p-2 md:p-3 transition-all duration-300">
                <div
                  {...getRootProps()}
                  className={`${
                    isDragActive && "bg-[#cac7c7ad]"
                  } border-[5px] border-dashed p-5 w-full h-full cursor-pointer`}
                >
                  <div>
                    <div className="w-full flex justify-center">
                      <div className="text-[#95afc0] opacity-[0.55] flex w-fit gap-x-8">
                        <FaFileImage
                          className={`-rotate-45 w-10 h-10 sm:w-14 sm:h-14 duration-300 transition-all ${
                            selectedFile && "text-blue-500"
                          }`}
                        />
                        <FaFilePdf
                          className={`w-10 h-10 sm:w-14 sm:h-14 duration-300 transition-all  ${
                            selectedFile && "text-red-500"
                          }`}
                        />
                        <IoDocumentText
                          className={`rotate-45 w-10 h-10 sm:w-14 sm:h-14 duration-300 transition-all   ${
                            selectedFile && "text-gray-500"
                          }`}
                        />
                      </div>
                    </div>
                    <input {...getInputProps()} />
                    <p className="text-[#130f40] mt-7 opacity-[0.65] font-[PublicSans] text-sm sm:text-base transition-all duration-300">
                      Drag and drop a PDF file here or browse your computer. Max
                      300 pages allowed.
                    </p>
                  </div>
                  {selectedFileName && (
                    <span className="text-blue-600 text-sm font-[PublicSans]">
                      {selectedFileName}
                    </span>
                  )}
                  {isError && (
                    <span className="text-red-600 text-sm font-[PublicSans]">
                      Please select a PDF file.
                    </span>
                  )}
                  <div className="flex flex-col">
                    {fileRejections[0]?.errors.map(
                      (errItem: any, index: number) => {
                        if (errItem.code === "file-too-large") {
                          return (
                            <span
                              key={index}
                              className="text-red-600 text-sm font-[PublicSans]"
                            >
                              Yours file is larger than 20mb.
                            </span>
                          );
                        } else if (errItem.code === "file-invalid-type") {
                          return (
                            <span
                              key={index}
                              className="text-red-600 text-sm font-[PublicSans]"
                            >
                              Only PDF files are allowed for upload.
                            </span>
                          );
                        }
                        return null;
                      }
                    )}
                  </div>
                </div>
                <LanguageDropdowns
                  languages={languages}
                  convertToLanguage={convertToLanguage}
                  currentLanguage={currentLanguage}
                  handleLanguageChange={handleLanguageChange}
                  validationMessage={validationMessage}
                />
              </div>
            </div>
            <div className="w-full flex flex-col sm:flex-row gap-y-2 sm:gap-y-0 sm:gap-x-3">
              <button
                type="button"
                className="bg-[#3498db] hover:bg-[#327cad] py-2 px-2 font-[PublicSans] font-medium text-[#fff] transition-all duration-300 w-full rounded-sm"
                onClick={() => navigate("/")}
              >
                Back to dashboard
              </button>
              <button
                className="py-2 px-2 w-full text-[#f1f1f1] font-[PublicSans] rounded-sm bg-slate-600 hover:bg-slate-700 transition-all duration-200 shadow-md"
                type="button"
                onClick={() => handleUpload()}
              >
                Submit
              </button>
            </div>
          </div>
        )}
        {isUploadSuccess && (
          <FileUploadSuccess
            handleClick={downloadPDF}
            handleCancelPopUp={hideSuccessPopup}
          />
        )}
        {isUploadError && <FileUploadError handleClick={retryFileUpload} />}
      </div>
      {/* Loader */}
      {isUploadInProgress && (
        <div className="bg-slate-900/40 absolute h-full w-full top-0 flex justify-center items-center">
          <div className="border-gray-300 h-20 w-20 animate-spin rounded-full border-[6px] border-t-slate-900" />
        </div>
      )}
    </div>
  );
};

export default memo(QuickUpload);
