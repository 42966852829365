import api from "../api/api";

export const uploadFile = (
  param: any,
  sourceLang: string,
  targetLang: string
) => {
  const response = api.post(
    `/v1/convert_language?source_lang=${sourceLang}&target_lang=${targetLang}`,
    param
  );
  return response;
};
