import axios, { AxiosError, AxiosResponse } from "axios";
import environment from "../config/environment";
import { toast } from "react-toastify";
import globalRouter from "../lib/globalRouter";

const api = axios.create({
  baseURL: environment.baseURL,
});

api.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["authorization"] = token;
    }
    return config;
  },
  (error) => {
    console.log("Axios request interseptor error", error);
  }
);

api.interceptors.response.use(
  // Handle the success response
  (response: AxiosResponse) => {
    const { data } = response;
    const token = response.headers["authorization"];
    if (token) {
      localStorage.setItem("access_token", token);
    }
    return data;
  },
  // Handle the error response
  (error: AxiosError | any) => {
    const errorMessage = error.response?.data?.errors?.[0]
      ? error.response.data.errors[0]
      : "Sorry something went wrong on our end!, Please try again later";
    if (error.response?.status === 401 && globalRouter.navigate) {
      localStorage.removeItem("access_token");
      toast.error(errorMessage);
      globalRouter.navigate("/login");
    } else {
      toast.error(`${errorMessage}`);
    }
    return Promise.reject(error);
  }
);

export default api;
