interface Language {
  code: string;
  name: string;
}

const LanguageDropdowns = ({
  languages,
  currentLanguage,
  convertToLanguage,
  handleLanguageChange,
  validationMessage,
}: {
  languages: Language[];
  currentLanguage: string;
  convertToLanguage: string;
  handleLanguageChange: any;
  validationMessage: string;
}) => {
  return (
    <div className="w-full">
      <div className="sm:flex space-y-3 sm:space-y-0 sm:space-x-4 mt-3 w-full">
        {/* Current File Language Dropdown */}
        <div className="relative w-full">
          <label
            htmlFor="currentLanguage"
            className="block text-sm text-gray-700 font-[PublicSans] float-start mb-1"
          >
            Current File Language
          </label>
          <select
            id="currentLanguage"
            name="currentLanguage"
            value={currentLanguage}
            onChange={(e) => handleLanguageChange("current", e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 outline-none sm:text-sm font-[PublicSans] py-1"
          >
            <option value="" className="font-[PublicSans]">
              Select language
            </option>
            {languages.map((language) => (
              <option
                key={language.name}
                value={language.code}
                className={`font-[PublicSans] py-3 ${
                  language.code === currentLanguage && "bg-slate-600 text-white"
                }`}
              >
                {language.name}
              </option>
            ))}
          </select>
        </div>

        {/* Convert To Language Dropdown */}
        <div className="relative w-full">
          <label
            htmlFor="convertToLanguage"
            className="block text-sm text-gray-700 font-[PublicSans] float-start mb-1"
          >
            Translate to
          </label>
          <select
            id="convertToLanguage"
            name="convertToLanguage"
            value={convertToLanguage}
            onChange={(e) => handleLanguageChange("convertTo", e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 outline-none sm:text-sm font-[PublicSans] py-1"
          >
            <option value="" className="font-[PublicSans]">
              Select language
            </option>
            {languages.map((language) => (
              <option
                key={language.name}
                value={language.code}
                className={`font-[PublicSans] py-3 ${
                  language.code === convertToLanguage &&
                  "bg-slate-600 text-white"
                }`}
              >
                {language.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {validationMessage && (
        <span className="font-[PublicSans] text-sm text-red-600">
          {validationMessage}
        </span>
      )}
    </div>
  );
};

export default LanguageDropdowns;
