import { Suspense } from "react";
import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "./components/renderings/Login/Login";
import Layout from "./Layout/Layout";
import QuickUpload from "./components/renderings/QuickUpload/QuickUpload";
import { ProtectedRoute } from "./lib/ProtectedRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UnAuth } from "./lib/UnAuth";
import globalRouter from "./lib/globalRouter";
import Dashboard from "./components/renderings/Dashboard.tsx/Dashboard";
import NotFoundPage from "./components/renderings/NotFoundPage/NotFoundPage";

function App() {
  const navigate = useNavigate();
  globalRouter.navigate = navigate;

  return (
    <div className="w-full h-full">
      <Suspense fallback={null}>
        <Routes>
          <Route element={<UnAuth />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Layout />}>
              <Route path="/upload" element={<QuickUpload />} />
              <Route path="/" element={<Dashboard />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
      <ToastContainer />
    </div>
  );
}

export default App;
