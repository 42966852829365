import api from "../api/api";

interface UserCredentials {
  email: string;
  password: string;
}

export const logIn = async (params: UserCredentials) => {
  const response = await api.post("/v1/signin", params);
  return response;
};

export const logOut = async () => {
  const response = await api.delete("/v1/signout");
  return response;
};
