import { Link } from "react-router-dom";
import { AxiosResponse } from "axios";
import { getFileReportData } from "../../../services/fileReportService";
import { memo, useCallback, useEffect, useState } from "react";
//Icons
import { FaFile, FaCopy } from "react-icons/fa";
import { FaCloudUploadAlt } from "react-icons/fa";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileReport, setFileReport] = useState({
    totalFiles: 0,
    totalPages: 0,
  });

  useEffect(() => {
    setIsLoading(true);
    getFileReportData()
      .then((res: AxiosResponse | any) => {
        setFileReport({
          totalFiles: res?.total_files,
          totalPages: res?.total_pages,
        });
      })
      .catch((err: Error) => {
        console.log("getFileReportData err", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const LoadingAnimation = useCallback(() => {
    return (
      <div className="animate-spin border-t-4 border-blue-500 border-solid h-6 w-6 rounded-full"></div>
    );
  }, []);

  return (
    <div className="flex h-full w-full shadow-inner p-2">
      <div className="container mx-auto mt-8 transition-all duration-200 p-3 sm:p-5 bg-white rounded-md shadow-lg h-fit">
        <h1 className="text-xl sm:text-3xl font-bold mb-4 font-[PublicSans]">
          Translation Report
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 transition-all duration-300">
          <div className="bg-blue-200 p-4 rounded-sm flex items-center justify-between shadow-md">
            <div>
              <h2 className="text-lg sm:text-xl font-bold mb-2 font-[PublicSans] transition-all duration-300">
                Total Files
              </h2>
              {isLoading ? (
                <LoadingAnimation />
              ) : (
                <p className="text-xl sm:text-2xl font-semibold font-[PublicSans] transition-all duration-300">
                  {fileReport.totalFiles}
                </p>
              )}
            </div>
            <FaFile className="text-4xl text-blue-600" />
          </div>
          <div className="bg-green-200 p-4 rounded-sm flex items-center justify-between shadow-md">
            <div>
              <h2 className="text-lg sm:text-xl font-bold mb-2 font-[PublicSans] transition-all duration-300">
                Total Pages
              </h2>
              {isLoading ? (
                <LoadingAnimation />
              ) : (
                <p className="text-xl sm:text-2xl font-semibold font-[PublicSans] transition-all duration-300">
                  {fileReport.totalPages}
                </p>
              )}
            </div>
            <FaCopy className="text-4xl text-green-600" />
          </div>
        </div>
        {/* Navigation Button */}
        <div className="mt-6">
          <Link
            to="/upload"
            className="mt-4 bg-purple-500 hover:bg-purple-600 text-white py-2 px-4 rounded-sm font-[PublicSans] flex w-fit gap-x-2 items-center transition-all duration-300"
          >
            <FaCloudUploadAlt className="w-7 h-7" />
            <span>Go to Upload Page</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default memo(Dashboard);
