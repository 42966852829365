import { Outlet } from "react-router-dom";
import FooterComponent from "../components/components/FooterComponent/FooterComponent";
import HeaderComponent from "../components/components/HeaderComponent/HeaderComponent";

const Layout = () => {
  return (
    <div className="w-full h-full flex flex-col">
      <HeaderComponent />
      <main className="w-full h-full flex flex-col bg-[#f7f7ff]">
        <Outlet />
      </main>
      <FooterComponent />
    </div>
  );
};

export default Layout;
