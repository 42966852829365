const defaultEnv = {
  baseURL: "https://translateapi.yavar.ai/",
};
const devEnv = {
  baseURL: "https://translateapi.yavar.ai/",
};
const prodEnv = {
  baseURL: "https://translateapi.yavar.ai/",
};

const environment =
  process.env.NODE_ENV === "production"
    ? { ...defaultEnv, ...prodEnv }
    : { ...defaultEnv, ...devEnv };

export default environment;
